<template>
  <div class="container">
    <RoleCard theme="student">
      <template #label>Приглашение от участника НТО</template>
      <template #label_img>
        <img
          src="@/assets/images/reg_school.svg"
          alt="иконка" />
      </template>
      <template #body>
        <div class="row">
          <div class="col-md-8 col-sm-12 col-xs-12">
            <p
              v-if="participant"
              class="mb-l">
              Участник НТО {{ participant.first_name }}
              {{ participant.last_name }} хочет пригласить вас как своего
              наставника.
            </p>
            <div v-if="error">
              <p class="form-error">{{ error }}</p>
            </div>
            <template v-if="!pending">
              <div v-if="noUser">
                <p class="mb-l">
                  Войдите в&nbsp;учетную запись участника, чтобы принять
                  приглашение.
                </p>
                <BaseButton
                  tag="a"
                  :href="`${$store.state.clientApiPrefix}/auth/go?next=/registration?next=${$route.fullPath}`"
                  >Войти</BaseButton
                >
              </div>
              <div v-else-if="!isMentor">
                <p class="mb-l">
                  Вы не можете принять это приглашение, так как не являетесь
                  Наставником.
                </p>
                <BaseButton
                  tag="router-link"
                  :to="{ name: 'user-index' }"
                  >В кабинет участника</BaseButton
                >
              </div>
              <div
                v-else
                class="mt-s">
                <ValidationObserver
                  ref="form"
                  tag="form"
                  @submit.prevent="acceptInvite">
                  <div class="mb-s">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      mode="aggressive">
                      <BaseSelect
                        v-model="selectedOrganization"
                        :options="organizations"
                        theme="reg"
                        label="title"
                        :allow-empty="false"
                        preselect-first
                        placeholder="Укажите организацию"></BaseSelect>
                      <div
                        v-if="errors.length"
                        class="form-error">
                        Нужно выбрать организацию
                      </div>
                    </ValidationProvider>
                  </div>
                  <div
                    v-if="isSchoolSelected"
                    class="mt-m mb-m">
                    Вы&nbsp;не&nbsp;можете принять приглашение от&nbsp;выбранной
                    организации, так как она является образовательным
                    учреждением. Пожалуйста, выберите другую организацию или
                    вышлите приглашение этому участнику самостоятельно.
                  </div>
                  <BaseButton
                    type="submit"
                    :disabled="isSchoolSelected"
                    >Принять приглашение</BaseButton
                  >
                </ValidationObserver>
              </div>
            </template>
            <BaseSpinner v-else />
          </div>
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
/**
 * @page InviteMentor
 * Страница инвайта для наставника.
 * (Юзер просит наставника стать его ментором)
 */
import { mapGetters } from "vuex";
import RoleCard from "@/components/registration/RoleCard.vue";

import { talentRequest, request } from "@/services/api";
export default {
  name: "InviteMentor",
  components: { RoleCard },
  metaInfo() {
    return {
      title: "Приглашение наставника от участника НТО",
    };
  },
  data() {
    return {
      noUser: false,
      participant: null,
      pending: true,
      error: "",
      selectedOrganization: null,
    };
  },
  computed: {
    ...mapGetters({
      rootEvent: "rootEvent",
      user: "user/user",
      isMentor: "user/isMentor",
    }),
    organizations() {
      return this.$store.state.mentor.organizations.map((org) => {
        return {
          title: org.full_name || org.short_name,
          value: org.id,
          isSchool: !!org.educational_category,
        };
      });
    },
    isSchoolSelected() {
      return this.selectedOrganization?.isSchool;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.pending = true;
      // Получаем информацию о менторе
      try {
        const { data: participant } = await talentRequest({
          method: "GET",
          url: `/api/users/${this.$route.params.user}/`,
        });
        this.participant = participant;
      } catch (error) {
        if (error.status === 404) {
          this.error =
            "Участник с таким id не найден. Проверьте правильность ссылки, или попросите отправить вам ссылку повторно.";
        } else {
          this.error = error.message;
        }
      }
      // Получаем информацию о текущем пользователе
      try {
        await this.$store.dispatch("user/getMe");
      } catch (error) {
        this.noUser = true;
        this.pending = false;
        // Пользователю предлагают авторизоваться по ивайту от юзера
        window.dataLayer?.push({
          event: "ntoRegformEventOauth",
          eventCategory: "nto_auth_with_talant",
          eventAction: "oauth_suggest",
          inviteType: "invite_to_become_mentor",
        });
        return;
      }
      // @FIXME если у ментора нет организации то надо ее создать
      if (this.isMentor) {
        try {
          await this.$store.dispatch("mentor/getMyOrganizations");
        } catch (error) {
          this.error = error.message;
        }

        try {
          await this.$store.dispatch("mentor/getStudents", {
            page: 1,
            size: 50,
            force: true,
          });
        } catch (error) {
          // do nothing
        }
      }

      this.pending = false;
    },
    async acceptInvite() {
      const { code } = this.$route.params;
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      try {
        await request({
          method: "POST",
          url: `/mentor/invite`,
          data: {
            code,
            organization: this.selectedOrganization?.value,
            participant: this.participant?.id,
          },
        });
        this.$router.push({
          name: "mentor-index",
        });
      } catch (error) {
        this.error = error.message;
      }
    },
  },
};
</script>

<style></style>
